import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import ToyForm from '../components/ToyForm';
import { TOY_ADMIN } from '../graphql/Queries';

const ViewToy = (props) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const { match: { params: { id } = {} } = {} } = props;

  const [form] = Form.useForm();
  const history = useHistory();
  const [toyDetail, setToyDetail] = useState({});
  const [isToyLoading, setIsToyLoading] = useState(true);
  const [toyImages, setToyImages] = useState([]);
  const [featuredImage, setFeaturedImage] = useState([]);
  const [toyVideo, setToyVideo] = useState([]);

  const [toyAdmin] = useLazyQuery(TOY_ADMIN, {
    onCompleted: (response) => {
      const toyImagesArray = response?.toyAdmin?.images;
      const featuredImageObj = toyImagesArray?.shift();
      setToyImages(toyImagesArray);
      setFeaturedImage(featuredImageObj ? [featuredImageObj] : []);
      setToyVideo(
        response?.toyAdmin?.videoUrl
          ? { url: response?.toyAdmin?.videoUrl }
          : [],
      );
      setToyDetail({
        ...response?.toyAdmin,
        featuredImages: featuredImageObj ? [featuredImageObj] : [],
      });
      setIsToyLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  useEffect(() => {
    setIsToyLoading(true);
    setToyDetail([]);
    toyAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);
  const initialValues = {
    ...toyDetail,
    category: toyDetail?.categories?.map((category) => category?.id),
    ageGroup: toyDetail?.ageGroups?.map((ageGroup) => ageGroup?.id),
    brand: toyDetail?.brand?.id,
    material: toyDetail?.material?.id,
    eanNumber: toyDetail?.eanNumber,
    price: toyDetail?.price,
    carbonEmission: toyDetail?.carbonEmission,
    id: toyDetail?.toyNumber,
    facilitates: toyDetail?.facilitates?.map((facilitate) => facilitate?.id),
    tags: toyDetail?.tags?.map((tag) => tag?.id),
    genres: toyDetail?.genres?.map((genre) => genre?.id),
    includedContentMasters: toyDetail?.includedContents?.map(
      (contentMaster) => ({
        id: contentMaster?.id,
        quantity: contentMaster?.quantity,
      }),
    ),
    excludedContentMasters: toyDetail?.excludedContents?.map(
      (contentMaster) => ({
        id: contentMaster?.id,
        quantity: contentMaster?.quantity,
      }),
    ),
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`} />
        <div className="portal-header">View Toy</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TOY,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
            <div className="header-btn-wrapper">
              <Button
                className="common-button mr-16"
                size="middle"
                onClick={() =>
                  history?.push(
                    `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/edit/${id}`,
                  )
                }
                disabled={toyDetail?.isArchived}
              >
                Edit Toy
              </Button>
            </div>
          )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TOY,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
            <div className="header-btn-wrapper">
              <Button
                className="common-button mr-16"
                size="middle"
                onClick={() =>
                  history?.push(
                    `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/edit/${id}`,
                  )
                }
                disabled={toyDetail?.isArchived}
              >
                Edit Toy
              </Button>
            </div>
          )}
      </Portal>
      <Card className="full-height-with-nav" title="Toy Details">
        {isToyLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isToyLoading}
          />
        ) : (
          <div className="toy-form">
            <ToyForm
              form={form}
              initialFormValues={initialValues}
              isView
              toyImages={toyImages}
              toyDetail={toyDetail}
              featuredImage={featuredImage}
              toyVideo={toyVideo}
              setToyVideo={setToyVideo}
              setFeaturedImage={setFeaturedImage}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ViewToy;
